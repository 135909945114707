import { makeAutoObservable, runInAction } from "mobx";
import { MutableRefObject } from "react";
import EmbedApi from "../embed-api";
import { DataModel, SectionModel, ValueGroup, ValueModel } from "../types";
import { isSingleInput, json2data } from "../util";
import { data as content } from "../data/data";
import { ConversionDialogStore } from "./ConversionDialogStore";
import { Data } from "../embed-api/data";

function checkPrintFlag(win: Window) {
  return new URLSearchParams(win.location.search).get("print") === "1";
}

export type Partner = {
  logo: { url: string };
  "postal-code": string;
  homepage: string;
  "partner-description": string;
  "readable-address": string;
  readableTags: string[];
  name: string;
  "sort-index": number;
};

export class DataStore {
  readonly conversionDialogStore: ConversionDialogStore;
  readonly embedApi: EmbedApi;

  isInResultMode: boolean = false;
  wasInResultMode: boolean = false;
  showConversionDialog: boolean = false;

  isInPrintMode = false;
  isPrintReady = false;

  data: DataModel;
  sections: string[];
  activeSectionIndex: number = 0;
  partners: Partner[] = [];
  zipCodeFilter: string = "";

  constructor() {
    makeAutoObservable(this);
    this.conversionDialogStore = new ConversionDialogStore(this);
    this.embedApi = new EmbedApi();

    this.data = content;
    this.sections = Object.keys(content);
    this.loadPartners();

    if (typeof window !== "undefined" && checkPrintFlag(window)) {
      this.isInPrintMode = true;
      this.isInResultMode = true;
      this.wasInResultMode = true;

      window["__CO2CAlC_REHYDRATE_FOR_PRINT__"] =
        this.rehydrateForPrint.bind(this);
    }
  }

  private rehydrateForPrint(data: DataModel) {
    runInAction(() => {
      json2data(this.data, data);
      this.isPrintReady = true;
    });
  }

  async loadPartners() {
    // const response = await fetch(
    //   `${(window as any).CO2_CALCULATOR_ORIGIN ?? ""}/api/webflow`
    // );
    // // const response = await fetch("http://localhost:3000/api/webflow");
    // const body = await response.json();
    // const partners: Partner[] = body.items;
    const partners: Partner[] = [];
    runInAction(() => {
      this.partners = partners;
    });
  }

  goToNextSection = () => {
    if (this.activeSectionIndex === this.sections.length - 2) {
      // TODO go to evaluations page
      this.isInResultMode = true;
      this.goToSection(0);
    } else {
      this.goToSection(this.activeSectionIndex + 1);
    }
  };

  goToPreviousSection = () => {
    if (this.activeSectionIndex > 0) {
      this.goToSection(this.activeSectionIndex - 1);
    }
  };

  goToSection = (index: number) => {
    if (index < 0 || index >= this.sections.length) throw "Index out of bounds";
    this.activeSectionIndex = index;
    this.data[this.sections[index]].ref!.current.scrollIntoView();
  };

  goToNamedSection = (key: string) => {
    this.goToSection(this.sections.indexOf(key));
  };

  setSectionRef = (key: string, ref: MutableRefObject<any>) => {
    this.data[key].ref = ref;
  };

  get emission(): number {
    return Object.keys(this.data).reduce(
      (prev: any, curr: any) => prev + this.calculateSectionEmission(curr),
      0
    );
  }

  get activeSectionKey(): string {
    return this.sections[this.activeSectionIndex];
  }
  get activeSection(): SectionModel {
    return this.data[this.activeSectionKey];
  }
  getFilteredPartners(sectionKey: string): Partner[] {
    return this.partners
      .filter(
        (partner) =>
          partner.readableTags &&
          partner.readableTags.includes(sectionKey) &&
          (!partner["postal-code"] ||
            !this.zipCodeFilter ||
            this.zipCodeFilter.startsWith(partner["postal-code"].toString()) ||
            partner["postal-code"].toString().startsWith(this.zipCodeFilter))
      )
      .sort((a, b) => (a["sort-index"] ?? 0) - (b["sort-index"] ?? 0))
      .slice(0, this.zipCodeFilter ? this.partners.length : 3);
  }

  save = () => {
    this.embedApi.trigger("save", {
      total: this.emission,
      unit: "tCO₂eq/a",
      ...Object.keys(this.data).reduce((prev: any, curr: any) => {
        prev[curr] = this.calculateSectionEmission(curr);
        return prev;
      }, {}),
    } as Data);
  };

  setIsInResultMode = (value: boolean) => {
    this.isInResultMode = value;
    if (value && !this.wasInResultMode) {
      this.save();
      // this.setShowConversionDialog(true);
    }
    if (value) this.wasInResultMode = true;
  };

  setShowConversionDialog = (value: boolean = false) => {
    this.showConversionDialog = value;
  };

  updateScrollPosition = (value: number) => {
    let candidate = -1;
    for (const key of this.sections) {
      if (this.data[key].ref?.current !== undefined) {
        if (value >= this.data[key].ref?.current.offsetTop! - 300) {
          candidate++;
        } else {
          break;
        }
      }
    }

    if (candidate !== this.activeSectionIndex) {
      this.activeSectionIndex = candidate;
    }
  };

  calculateSectionEmission = (sectionKey: string): number => {
    const section = this.data[sectionKey];
    return (
      (section.value as number) ??
      Object.values(section.values!).reduce((prev: any, curr: any) => {
        if (isSingleInput(curr)) {
          return prev + (curr.footprint ? curr.footprint(this.data, curr) : 0);
        } else {
          return (
            prev +
            Object.values(curr.values).reduce(
              (innerPrev: any, innerCurr: any) => {
                return (
                  innerPrev +
                  (innerCurr.isEnabled && innerCurr.footprint
                    ? innerCurr.footprint(this.data, innerCurr)
                    : 0)
                );
              },
              0
            )
          );
        }
      }, 0)
    );
  };
  setZipCodeFilter = (filter: string) => {
    this.zipCodeFilter = filter;
  };

  enableValue = (
    sectionKey: string,
    valueKey: string,
    subkey: string,
    isEnabled: boolean
  ) => {
    (this.data[sectionKey].values[valueKey] as ValueGroup).values[
      subkey
    ].isEnabled = isEnabled;
  };
  updateData = (
    sectionKey: string,
    valueKey: string,
    value: number,
    subkey?: string
  ) => {
    if (!subkey) {
      (this.data[sectionKey].values[valueKey] as ValueModel).value = value;
    } else {
      (this.data[sectionKey].values[valueKey] as ValueGroup).values[
        subkey
      ].value = value;
    }
  };
  toggleItem = (
    sectionKey: string,
    valueKey: string,
    isActive: boolean,
    subkey?: string
  ) => {
    if (!subkey) {
      (this.data[sectionKey].values[valueKey] as ValueModel).isElectric =
        isActive;
    } else {
      (this.data[sectionKey].values[valueKey] as ValueGroup).values[
        subkey
      ].isElectric = isActive;
    }
  };
}
