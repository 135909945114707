import React from "react";
import { Partner } from "../store";

interface Props {
  partner: Partner;
  sectionKey: string;
}
export const PartnerCard = ({ partner, sectionKey }: Props) => {
  const regex = /<p>(.*?)<\/p>/;
  const corresp = regex.exec(partner["partner-description"]);
  return (
    <div className="relative pb-12 bg-white px-4 py-4 shadow-sm space-y-3 hover:shadow-md overflow-ellipsis overflow-hidden">
      <div className="flex-shrink-0 flex justify-center">
        <img className="h-48 object-contain" src={partner.logo.url} alt="" />
      </div>
      <div className="text-sx font-medium text-gray-500">
        {partner["readable-address"]}
      </div>
      <div className="text-lg">{partner.name}</div>
      <div
        className="text-sx font-medium text-gray-900 overflow-ellipsis overflow-hidden mb-32"
        dangerouslySetInnerHTML={{ __html: corresp ? corresp[1] : "" }}
      ></div>
      <a
        href={partner.homepage}
        target="_blank"
        rel="noreferrer"
        className="absolute right-6 bottom-6"
      >
        Mehr erfahren
      </a>
    </div>
  );
};
